@import "./variables.scss";
.nav-tabs {
    display: flex;
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
    border: none;
    li {
        background-color: $dark-grey;
        padding: 20px 40px;
        margin-right: 1px;
        &.active {
            background-color: $med-grey;
            border-radius: 4px 4px 0 0;
        }
        a {
            color: white;
            text-decoration: none;
        }
    }

    .nav-item.active {
        background: $med-grey;
        color: $text-color;
        border: none;
    }
}
.tab-pane {
    display: none;
    &.active {
        display: block;
        padding: 20px;
        background-color: $med-grey;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35);
        border-radius: 0 4px 4px 4px;
    }
}


.create-content {
  position: relative;
  color: $text-color;
  margin-left: 100px;
  padding: 16px;

  .content-header {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: min-content;
    align-items: center;
    column-gap: 20px;
  }

  .content-tabs {
    grid-area: 1 / 2 / 2 / 3;
    position: relative;
    min-height: 40px;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: none;
    background: $med-brown;
    color: $text-color;
    display: flex;
    align-items: center;

    &:before {
      position: absolute;
      left: 0;
      content: "";
      transform: translateX(-50%) rotate(45deg);
      height: 16px;
      width: 16px;
      background: $med-brown;
    }

    p {
      margin-left: 15px;
    }
  }

  .nav {
    margin: 0;
    padding: 4px 16px 0 16px;
    box-sizing: border-box;
  }

  .nav-item.nav-link {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 4px;
    text-decoration: none;
    color: $text-color;
  }

  .nav-item.active {
    background: $med-grey;
    font-weight: 400;
  }

  .tab-content {
    grid-area: 2 / 1 / 3 / 3;

    .tab-pane {
      border: none;
    }

    .tab-pane.active {
      cursor: auto;
      padding: 0;
      box-shadow: none;
      border-radius: none;
    }

    .tab-pane:hover {
      background: none;
    }
  }

  .content-body {
    margin-left: 60px;
    padding: 12px 1rem;
    background: $med-grey;
    border: solid 1px $med-brown;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submit-button {
    display: inline;
    width: auto;
    height: auto;
    color: white;
    cursor: pointer;
    padding: 6px 16px;
  }
}

