@import "./variables.scss";
input {
  color: $dark-grey;
  padding: 0 12px;
  font-size: 14px;
  height: 40px;
  border: none;
  border-radius: 4px;
}
.dropdown-select {
  margin-bottom: 20px;
  .dropdown-toggle {
    background-color: $dark-grey;
    color: white;
    border-radius: 4px;
  }
  .btn-default:active,
  .btn-default.active,
  &.open > .dropdown-toggle.btn-default {
    background-color: black;
    color: white;
    border-radius: 4px;
  }
  .dropdown-menu {
    border-radius: 4px;
    .icon {
      fill: $dark-grey;
    }
  }
}

.icon-input {
  margin: 5px 0;
  display: flex;
  align-items: center;
  &.filter {
    float: right;
  }
  input {
    padding: 0 12px;
    font-size: 14px;
    height: 40px;
    border: none;
    display: flex;
    flex: 1;
    border-radius: 0 2px 2px 0;
  }
  .icon-wrap {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-grey;
    border-radius: 2px 0 0 2px;
  }
  .icon {
    margin-left: 11px;
  }
  &.reversed {
    input {
      border-radius: 2px 0 0 2px;
      padding: 0;
      text-indent: 10px;
    }
    .icon-wrap {
      border-radius: 0 2px 2px 0;
    }
  }
}
.form-element.datePicker {
  display: inline-block;
  width: 70%;
  .react-datepicker__input-container,
  .react-datepicker-wrapper {
    width: 100%;
  }
  input.form-control {
    width: 100%;
  }
}

.text-input {
  flex: 1;
  margin-bottom: 0.5rem;
  textarea,
  input {
    font-family: "Open Sans", sans-serif;
    background: $med-grey;
    border-radius: $border-radius;
    width: 100%;
    box-sizing: border-box;
    border-color: $med-brown;
    color: white;
    padding: 0.5rem;

    &:focus {
      background: $med-grey;
      color: white;
    }
  }
}

.drop-down-select {
  &.dropdown {
    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background: $dark-grey;
      color: $text-color;
      cursor: pointer;
      border-radius: $border-radius;
      padding: 6px 16px;
    }

    .btn:hover {
      background: $dark-med-grey;
    }

    .btn:active,
    .btn:focus {
      background: $dark-med-grey;
    }

    &.has-error {
      .btn {
        background: $med-red;
      }
    }
  }
}

.signup {
  align-items: center;
  width: auto;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  
  h2 {
    font-weight: 500;
  }
  
  .password-strength {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: 15px auto 0 auto;
  }

  .bottom-container {
    position: absolute;
    bottom: 30px;
  }
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  border-radius: 8px;
  height: 525px;
  box-shadow: -5px 0px 18px 3px rgb(0, 0, 0, .2);

  h2 {
    font-weight: 500;
  }

  .vertical-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    margin-left: 1rem;
    margin-right: 2rem;

    img {
      width: 100%;
    }
  }

  form {
    flex: 1;

    .reset-password {
      text-align: right;
      font-size: 0.8rem;

      span {
        color: $text-color;
        margin-right: 1rem;
      }
    }

    .form-input {
      flex: 1;
      margin-bottom: 0.5rem;
      textarea,
      input {
        font-family: "Open Sans", sans-serif;
        background: $dark-grey;
        border-color: $dark-grey;
        width: 100%;
        box-sizing: border-box;
        color: white;
        padding: 0.5rem 1rem;

        &::placeholder {
          color: $text-color;
          font-size: 0.8rem;
        }

        &:focus {
          background: $dark-grey;
          color: white;
        }
      }
    }

    .form-control.is-invalid {
      border-color: #dc3545;
    }

    .submit-button {
      display: inline;
      width: auto;
      height: auto;
      cursor: pointer;
      padding: 6px 16px;
      float: right;
    }
    
    // .bottom-container {
    //   position: absolute;
    //   bottom: 80px;
    // }
  }
}

.invite-form,
.document-form {
  background-color: $med-grey;
  display: flex;
  align-items: center;

  form {
    flex: 1;

    .form-input {
      flex: 1;
      margin-bottom: 0.5rem;
      textarea,
      input {
        font-family: "Open Sans", sans-serif;
        background: $dark-grey;
        border-color: $dark-grey;
        width: 100%;
        box-sizing: border-box;
        color: white;
        padding: 0.5rem 1rem;

        &::placeholder {
          color: $text-color;
          font-size: 0.8rem;
        }

        &:focus {
          background: $dark-grey;
          color: white;
        }
      }
    }

    .form-control.is-invalid {
      border-color: #dc3545;
    }

    .submit-button {
      display: inline;
      width: auto;
      height: auto;
      cursor: pointer;
      padding: 6px 16px;
      float: right;
    }
  }
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
