@import "../../../styles/variables.scss";

.asset-detail-sidebar {
  padding: 10px 5px 0 15px;

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }
  dt {
    font-weight: normal;
  }
  dl {
    font-size: 13px;
    line-height: 1.5rem;
    grid-template-columns: 1.5fr 2fr;
  }
  dd {
    text-align: right;
  }
}

.center-self {
  align-self: center;
}

.asset-detail {
  display: grid;
  height: 89.5vh;
  grid-template-columns: 1fr 3fr;
  // background: $dark-med-grey;
  color: $text-color;
  font-family: "Open Sans", sans-serif;
}
.asset-sidebar {
  dt {
    color: $light-grey;
  }
  dl {
    font-size: 14px;
    line-height: 1.5rem;
    color: $text-color;
  }
}

.asset-stats {
  display: grid;
  grid-template-areas:
    "next-step not-on-hold next-step-stats"
    "next-step loss-mit-activity next-step-stats";
  grid-gap: 20px;
  margin-bottom: 20px;
  h3 {
    font-weight: 100;
    text-transform: uppercase;
    padding: 20px;
    margin: 0;
    border-bottom: 1px solid $light-grey;
  }
  dt {
    text-align: right;
  }
  dd {
    text-align-last: left;
  }
  dl {
    line-height: 2rem;
  }
}
