@import "../../styles/variables.scss";

.asset-referral {
  
  .input-container {
    display: flex;
    justify-content: space-between;

      textarea {
        border: none;
        background: $dark-grey;
        padding: 1rem;
      }

      .is-invalid {
        border: solid 1px $med-red;
      }
  }

  label {
    color: $light-grey;
  }
}