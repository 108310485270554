.error-container {
  display: flex;
  padding: 25px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  .centered {
    text-align: center;
  }
}