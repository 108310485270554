@import "../../styles/variables.scss";

.logo {
  height: 100%;
  padding: 0.2rem 1.5rem;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
}
.app-header {
  // background-color: $med-grey;
  display: flex;
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 45px;

  .user-avatar {
    position: sticky;
    left: 46%;
    margin: auto;
  }

  .main-nav {
    ul {
      height: 100%;
    }
    margin-left: auto;
    flex: 1 1 auto;
    max-width: 700px;
    .link {
      text-align: center;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      &:hover {
        color: $dark-med-grey;
        text-decoration: none;
      }
      &.active {
        color: $brand-primary-blue;
        text-decoration: none;
        border-bottom: 4px solid $brand-primary-blue;
      }
    }
  }
}
