@import "../../styles/variables.scss";

.circle-icon {
  background: $brand-primary-dark-blue;
  padding: 18px;
  color: $text-color;
  font-size: 3rem;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}