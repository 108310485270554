@import "../../styles//variables.scss";

.document-delete {
  h2 {
    font-weight: 500;
    font-size: 24px;
  }

  .txt-input {
    width: 100%;
  }
}