@import "./variables.scss";
h1,
h2,
h3,
h4 {
    font-weight: 100;
}

a {
    cursor: pointer;
    color: $brand-primary-blue;
    text-decoration: none;
    transition: $default-ease;
    &:hover {
        color: darken($brand-primary-blue, 20%);
        text-decoration: underline;
    }
}
