@import "./variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  // background: $dark-med-grey;
  // color: $text-color;
}

html {
  // background-color: $body-bg;
  font-family: "Open Sans", sans-serif;
  color: white;
}

.wrapper {
  display: grid;
  grid-template-rows: 54px auto 100px;
  min-height: 100vh;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  fill: white;
  margin: -1px 5px 0 0;
}

.react-icon {
  display: inline-block;
  vertical-align: middle;
}

.side-tabs-layout {
  display: grid;
  grid-template-columns: max-content 1fr;

  .side-tabs {
    padding-top: 61px;
    display: flex;
    flex-direction: column;
  }

  .side-tab {
    display: inline-block;
    padding: 6px;
    background: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 55px;
    width: 65px;

    .inner-box {
      // background: $dark-grey;
      height: 40px;
      width: 40px;
      text-align: center;
      vertical-align: middle;
      position: relative;
      box-sizing: border-box;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        position: absolute;
        top: 5px;
        right: 0;
        transform: translateX(50%);
      }
    }
  }

  button.selected {
    // background: $med-grey;
  }

  button:active {
    // background: $dark-grey;
  }
}

.container-pane {
  background-color: $med-grey;
  box-shadow: $default-shadow;
  font-size: 13px;
  padding: 20px;
  border-radius: $border-radius;
}

.bg-image {
  background-color: rgba(255, 255, 255);
  background-size: cover;
  background-repeat: no-repeat;
  // background-position: center;
  position: relative;
  box-shadow: 0px 19px 96px 44px rgba(0, 0, 0, 0.05) inset;
}

.registration-bg {
  background-color: rgba(255, 255, 255);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  box-shadow: 0px 19px 96px 44px rgba(0, 0, 0, 0.2) inset;
}

.fullhieght {
  height: 100vh;
}

.heightWithHeader {
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
}
