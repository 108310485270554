@import "../../styles/variables.scss";

.getting-started {
  height: 100%;
  color: $text-color;

  h1 {
    margin-top: 200px;
    text-align: center;
    font-size: 2.5em;
  }
  h3 {
    text-align: center;
    font-size: 1em;
    margin-bottom: 50px;
  }

  .icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 250px;

    .circle-icon {
      margin-bottom: 10px;
    }

    a {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 50px;
      padding-left: 50px;

      text-decoration: none;
    }
  }
}