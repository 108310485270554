$body-bg: #212121;
// $brand-primary-blue: #3ea9f5;
$brand-primary-blue: #106AF1;
$brand-primary-dark-blue: #0b85db;
$dark-grey: #141414;
$dark-med-grey: #1f2020;
$med-grey: #333;
$light-grey: #999;
$lighter-grey: #cecece;
$light-med-grey: #777;
$follow-asset: #da9059;
$default-ease: all 0.2s ease-in-out;
$default-shadow: 0 10px 20px rgba(0, 0, 0, 0.35);
$zindex-modal: 3;
$zindex-modal-background: 2;
$border-radius: 4px;
$med-brown: #685050;
$text-color: #ffffff;
$med-red: #ff4c4c;
$line-color: #8f8f8f;
$border-radius: $border-radius;
