@import "../../styles/variables.scss";

.main-footer {
  height: 40px;
  // box-shadow: 0px -1px 17px 1px rgba(0, 0, 0, 0.75);
  padding: 30px;
  // color: $light-grey;
  // background: $dark-med-grey;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
