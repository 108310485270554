@import "./variables.scss";

.auth-page {
  overflow: hidden;
  .auth-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;

    // color: black;
    // background-color: #fff;

    margin-left: auto;
    padding: 0px 20px;
    // border-bottom: 1px solid grey;

    .auth-logo {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }

    .auth-link {
      flex: 1 1 auto;
      background-color: $dark-med-grey;
      text-align: center;
      padding: 0;
      color: $light-grey;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &:hover {
        color: $text-color;
        background-color: $dark-grey;
        text-decoration: none;
      }
      &.active {
        color: $text-color;
        background-color: $med-grey;
        text-decoration: none;
      }
    }
  }

  .form-panel {
    position: fixed;
    left: 70%;
    bottom: 25%;
    border-radius: 8px;
    background-color: rgba(247, 247, 247, .8);
    // width: 30%;
    height: auto;
    // border-left: 1px solid grey;
    // padding-left: 1rem;

    .bottom-container {
      width: 100%;
      position: absolute;
      margin: 0 auto;
      bottom: 10px;
    }
  }
}

.portfolio-assets {
  .portfolio-assets-layout {
    display: grid;
    grid-template-columns: 95px 1fr;

    .portfolio-assets {
      margin-top: 20px;
    }
  }
}

.lawfirms-list {
  height: 75vh;
  display: flex;
  align-items: center;
  margin-top: 2.5vh;
}

.documents-list {
  margin-top: 1vh;
  height: 82vh;
  display: flex;
  align-items: center;
}

.asset-import {
  .import-box {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .asset-data-mapper {
    height: 580px;
  }

  .dark-button {
    background: $dark-med-grey;
  }
}
