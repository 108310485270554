@import "../../styles/variables.scss";

.milestone-holds {
  padding: 0;
  // height: 40vh !important;

  .hold-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .hold-title {
    font-size: 1rem;
    align-self: center;
  }

  .closed {
    text-decoration: line-through solid 1px $light-med-grey;
    color: $light-med-grey;
  }

  .close-hold, .reopen-hold {
    width: fit-content;
    height: auto;
    padding: 4px;
    font-size: .75em;
  }
}
