.error {
  color: #f60017;
}

.cc-container {
  padding-bottom: 2rem;

  h2 {
    font-weight: 500;
    font-size: 24px;
  }
}

.pe-light {
  background-color: #fbfbfb;
}

.pe-dark {
  background-color: #080810;
}

.payment-element {
  padding-left: 1rem;
  padding-right: 1rem;
}

.payment-buttons {
  display: flex;
  padding-top: .5rem;
  
  .save{
    border-radius: .3rem;
    height: 2.5rem;
  }

  .skip{
    width: 50%;
    margin-left: 1rem;
    height: 2.5rem;
  }
}