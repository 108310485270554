@import "./variables.scss";
button,
.btn {
    border: none;
    padding: 0 12px;
    font-size: 14px;
    height: 40px;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease-in-out;
    &.btn-default {
        background-color: $dark-grey;
        color: white;
    }
    &.link {
        background: none;
        color: $brand-primary-blue;

        &:hover {
            color: darken($brand-primary-blue, 20%);
        }
    }
    &.primary {
        background-color: $brand-primary-blue;
        color: white;
        border-radius: 2px;
        &:hover {
            background-color: darken($brand-primary-blue, 20%);
        }
    }
    &.dark {
        margin: 5px 0px 20px 0;
        background-color: $dark-grey;
        padding: 6px 11px;
        border-radius: 4px;
    }
    &.follow-asset {
        width: 100%;
        background-color: $follow-asset;
        padding: 10px;
        border-radius: 4px;
        :hover {
            background-color: darker($follow-asset, 20%);
        }
    }
    &.close {
        padding: 0;
        cursor: pointer;
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        width: auto;
        height: auto;
    }
    &.inline {
        width: auto;
        margin: 0 10px;
    }
}

.button-group {
    display: flex;
    justify-content: center;
    padding: 0 5px;
    button {
        //flex: 1 1 auto;
        margin: 5px 5px 20px;
        border: none;
        flex: 0 0 50%;
        background-color: $light-grey;
        padding: 6px 11px;
        border-radius: 4px;
        font-size: 14px;
    }
}
.modal-body .btn {
    width: auto;
}

.btn-link {
    color: $brand-primary-blue;
    font-size: 1rem;
    &:hover {
        color: darken($brand-primary-blue, 20%);
    }
}
