@import "../../styles/variables.scss";

.asset-referral-table {

  .ag-row > :first-child {
    color: $brand-primary-dark-blue;
    letter-spacing: 1px;
    font-weight: 400;
  }

  .ag-header-row > :last-child {
    .ag-header-cell-label {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .asset-referral-status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  
    .btnGroup {
      display: flex;
      button {
        height: 28px;
        margin: 0 4px;
        font-weight: 200;
      }
    }
    
    
  }
  
}