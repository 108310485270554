@import "../../styles//variables.scss";

.react-date-picker-popper {
  z-index: 999;
}

.date-picker-select {
  &.dropdown {
    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background: $dark-grey;
      color: $text-color;
      cursor: pointer;
      border-radius: $border-radius;
      padding: 6px 16px;
    }

    .btn:hover {
      background: $dark-med-grey;
    }

    .btn:active,
    .btn:focus {
      background: $dark-med-grey;
    }

    &.has-error {
      .btn {
        background: $med-red;
      }
    }
  }
}
div.react-datepicker-popper {
  z-index: 1000;
}