.h-list {
    display: flex;
    justify-content: flex-end;
    li {
        width: 125px;
    }
}

dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    dt {
        text-align: left;
    }
    dd {
        text-align-last: right;
    }
}
