@import "./variables.scss";
@import "~ag-grid-enterprise/styles/ag-grid";
@import "~ag-grid-enterprise/styles/ag-theme-alpine";
@import "~ag-grid-enterprise/styles/ag-theme-material";



// @import "~ag-grid-community/src/styles/ag-grid.scss";
// @import "~ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark-mixin";
// @import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
// @import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";

.tableStyles {
  // @include ag-theme-material();

  font-family: "IBM Plex Sans";
  font-style: normal;
}

.ag-tool-panel-wrapper {
  width: 100%;
}

.ag-react-container {
  width: 100%;
}