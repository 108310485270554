@import '../../styles/variables.scss';

.messages {
  .Toastify__toast {
    background: $dark-med-grey;
    color: $lighter-grey;
  }

  .Toastify__close-button {
    width: 15px;
  }
}