@import "./variables.scss";

nav {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        a {
            display: block;
            text-decoration: none;
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    margin: 10px 40px;
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        a {
            display: block;
            text-decoration: none;
            background-color: $dark-grey;
            padding: 5px 10px;
            border-radius: 4px;
        }
    }
    a {
        margin: 0 10px;
    }
}

// Color of main nav tabs
.nav-item.nav-link {
    color: $text-color;
}

.nav {
    margin-bottom: 0;
    padding-left: 0; // Override default ul/ol
    list-style: none;

    > li {
        position: relative;
        display: block;

        > a {
            position: relative;
            display: block;
            padding: 5px;
            &:hover,
            &:focus {
                text-decoration: none;
                //background-color: @nav-link-hover-bg;
            }
        }

        // Disabled state sets text to gray and nukes hover/tab effects
        &.disabled > a {
            //color: @nav-disabled-link-color;

            &:hover,
            &:focus {
                //color: @nav-disabled-link-hover-color;
                text-decoration: none;
                background-color: transparent;
                cursor: not-allowed;
            }
        }
    }

    // Open dropdowns
    .open > a {
        &,
        &:hover,
        &:focus {
            //background-color: @nav-link-hover-bg;
            //border-color: @link-color;
        }
    }
}
// Pills
// -------------------------
.nav-pills {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    overflow: hidden;
    .badge {
        border-radius: 12px;
        background-color: $dark-grey;
        padding: 3px 6px;
        margin-right: 5px;
        font-size: 900;
    }
    > li {
        float: left;
        padding: 2px 10px;

        // Links rendered as pills
        > a {
            color: white;
            text-decoration: none;
        }

        // Active state
        &.active {
            border-radius: 15px;
            margin: 0 10px 20px 0;
            background-color: $brand-primary-blue;
            .badge {
                background-color: $brand-primary-dark-blue;
            }
        }
        &.active > a {
            &,
            &:hover,
            &:focus {
                //color: ;
            }
        }
    }
}

// Stacked pills
.nav-stacked {
    > li {
        float: none;
        + li {
            margin-top: 2px;
            margin-left: 0; // no need for this gap between nav items
        }
    }
}
