@import '../../styles/variables.scss';

.tnc-page {
  display: flex;
  justify-content: center;
  align-items: center;

  strong, a, p, ul, ol, li {
    font-size: .8rem;
  }
  
  .tnc-container {
    height: 30rem;
    overflow-y: auto;
    background-color: #F9F9F9;
    border-radius: 0.5rem;
    border: 1px;
    margin-bottom: 2rem;
  
    h1 {
      padding-top: 20px;
      font-size: 2.5rem;
    }
    h2 {
      font-size: .8rem;
    }
  
    .tnc-content {
      margin: 20px 0;
      padding: 0 50px;
  
      p {
        padding-bottom: 15px;
      }
      
      section {
        padding-bottom: 15px;
        h3 {
          font-size: 1.2rem;
        }
        p {
          padding-bottom: 0;
        }
      }
    }
  }

  .tnc-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    position: sticky;
    top: 0;
    z-index: 0;
    background-color: $dark-grey;

    padding: 10px;
    border-radius: 5px;

    a, p {
      margin: 0;
      text-align: center;
      font-size: 1rem;
    }

    button {
      width: fit-content;
    }
  }
}

#privacy-policy {
  margin-top: 100px;
}

.accept-btn {
  padding-top: 2rem;
  width: 10rem;
}