@import "../../styles/variables.scss";

.modal {
  .modal-content {
    background-color: #333;
    color: $text-color;

    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .close {
        color: $text-color;
      }

      .modal-title {
        font-size: 1.5rem;
        font-weight: bold;
        color: $text-color;
      }
    }

    .modal-body {
      span {
        font-size: 1.2rem;
        font-weight: bold;
        color: $text-color;
      }
    }
  }
}
